<template>
  <sdCards v-if="profileState">
    <template v-slot:title>
      <div class="setting-card-title">
        <sdHeading as="h4"> {{ i18n.t('profileView.personalData') }} </sdHeading>
      </div>
    </template>
    <a-row type="flex" justify="center">
      <a-col :xs="24">
        <div class="settings-form__wrapper">
          <BasicFormWrapper>
            <a-form :model="profileUpdate" ref="profile" :rules="rules">
              <a-form-Item ref="last_name" name="last_name" :label="i18n.t('editProfileView.lastName')">
                <a-input
                  v-model:value="profileUpdate.last_name"
                  :placeholder="profileState.last_name ? profileState.last_name : i18n.t('editProfileView.lastName')"
                  :disabled="!changableField.profile"
                />
              </a-form-Item>
              <a-form-Item ref="first_name" name="first_name" :label="i18n.t('editProfileView.firstName')">
                <a-input
                  v-model:value="profileUpdate.first_name"
                  :placeholder="profileState.first_name ? profileState.first_name : i18n.t('editProfileView.firstName')"
                  :disabled="!changableField.profile"
                />
              </a-form-Item>
              <a-form-Item :label="i18n.t('editProfileView.middleName')">
                <a-input
                  v-model:value="profileUpdate.middle_name"
                  :placeholder="
                    profileState.middle_name ? profileState.middle_name : i18n.t('editProfileView.middleName')
                  "
                  :disabled="!changableField.profile"
                />
              </a-form-Item>
              <a-form-item ref="date" name="date" :label="i18n.t('editProfileView.birthDate')">
                <a-date-picker
                  style="width: 100%"
                  :disabled="!changableField.profile"
                  v-model:value="profileUpdate.date"
                  :placeholder="
                    profileState.date_birth
                      ? i18n.d(profileState.date_birth, 'short')
                      : i18n.t('editProfileView.birthDate')
                  "
                  :showToday="false"
                  :format="i18n.locale.value == 'ru' ? 'DD.MM.YYYY' : 'MM-DD-YYYY'"
                  :locale="i18n.locale.value == 'ru' ? locale : ''"
                />
              </a-form-item>
              <a-form-item ref="country" name="country" :label="i18n.t('authPage.country')">
                <a-select
                  v-model:value="profileUpdate.country"
                  style="width: 100%"
                  :placeholder="i18n.t('countries.' + profileState.country)"
                  :show-search="true"
                  size="large"
                  :required="true"
                  option-filter-prop="label"
                  :options="translateCountries"
                  :disabled="!changableField.profile"
                >
                </a-select>
              </a-form-item>
              <sdButton
                v-if="!changableField.profile"
                size="default"
                outlined
                style="margin-right: 20px"
                type="primary"
                @click="changableData('profile')"
              >
                {{ i18n.t('profileView.change') }}
              </sdButton>
              <div v-else class="setting-form-actions">
                <sdButton
                  size="default"
                  :loading="isLoading"
                  outlined
                  style="margin-right: 20px"
                  type="success"
                  @click="handleProfileFinish"
                >
                  {{ i18n.t('profileView.update') }}
                </sdButton>
                <sdButton size="default" outlined @click="changableData('profile')" type="danger">
                  {{ i18n.t('profileView.cancel') }}
                </sdButton>
              </div>
            </a-form>
          </BasicFormWrapper>
        </div>
      </a-col>
      <a-col :xs="24">
        <div
          :style="{
            height: '50px',
            marginLeft: '25px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }"
          class="notification-header"
        >
          <sdHeading as="h6">
            {{ i18n.t('authPage.email') }}
          </sdHeading>
        </div>
        <div class="settings-form__wrapper">
          <BasicFormWrapper>
            <a-form :model="contactUpdate" :rules="rules" @finish="handleEmailFinish" ref="emailForm">
              <!--<a-form-item :label="i18n.t('global.newEmail')" ref="emailinput" name="email">-->
              <a-form-item label="" ref="emailinput" name="email">
                <a-input
                  v-model:value="contactUpdate.email"
                  :placeholder="contactsState.email"
                  :disabled="!changableField.email"
                  required
                />
              </a-form-item>
              <a-form-item
                ref="password"
                name="password"
                style="margin-top: 20px"
                :label="i18n.t('profileView.passwordConfirmationChange')"
                v-if="changableField.email"
              >
                <a-input-password v-model:value="contactUpdate.password" placeholder="••••••••" required />
              </a-form-item>
              <!--<sdButton
                v-if="!changableField.email"
                size="default"
                outlined
                style="margin-right: 20px"
                type="primary"
                @click="changableData('email')"
                > {{ i18n.t('profileView.change') }}
              </sdButton>
              <div v-else class="setting-form-actions">
                <sdButton
                  size="default"
                  htmlType="submit"
                  :loading="isLoading"
                  outlined
                  style="margin-right: 20px"
                  type="success"
                  > {{ i18n.t('profileView.update') }}
                </sdButton>
                <sdButton size="default" outlined @click="changableData('email')" type="danger"
                  > {{ i18n.t('profileView.cancel') }}
                </sdButton>
              </div>-->
            </a-form>
          </BasicFormWrapper>
        </div>
      </a-col>
      <a-col :xs="24">
        <div
          :style="{
            height: '50px',
            marginLeft: '25px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }"
          class="notification-header"
        >
          <sdHeading as="h6">
            {{ i18n.t('authPage.phone') }}
          </sdHeading>
        </div>
        <div class="settings-form__wrapper">
          <BasicFormWrapper>
            <a-form :model="phoneUpdate" :rules="rules" ref="phoneForm">
              <a-form-item ref="phone" name="phone" :label="i18n.t('authPage.phone')">
                <a-input
                  style="`min-witdh: 100%"
                  type="text"
                  :placeholder="contactsState.phone"
                  :value="countryCode + phoneUpdate.phone"
                  :disabled="true"
                  v-if="!changableField.phone"
                />
                <a-input
                  style="min-witdh: 100%"
                  type="text"
                  class="js-phone-mask"
                  ref="phoneItem"
                  v-model:value="phoneUpdate.phone"
                  @focus="changePhone"
                  v-else
                />
              </a-form-item>
              <a-form-item
                ref="password"
                name="password"
                style="margin-top: 20px"
                :label="i18n.t('profileView.passwordConfirmationChange')"
                v-if="changableField.phone"
              >
                <a-input-password v-model:value="phoneUpdate.password" placeholder="••••••••" required />
              </a-form-item>
              <sdButton
                v-if="!changableField.phone"
                size="default"
                outlined
                style="margin-right: 20px"
                type="primary"
                @click="changableData('phone')"
              >
                {{ i18n.t('profileView.change') }}
              </sdButton>
              <div v-else class="setting-form-actions">
                <sdButton
                  size="default"
                  :loading="isLoading"
                  outlined
                  style="margin-right: 20px"
                  type="success"
                  @click="handlePhoneFinish"
                >
                  {{ i18n.t('profileView.update') }}
                </sdButton>
                <sdButton size="default" outlined @click="changableData('phone')" type="danger">
                  {{ i18n.t('profileView.cancel') }}
                </sdButton>
              </div>
            </a-form>
          </BasicFormWrapper>
        </div>
      </a-col>
      <a-col :xs="24">
        <div
          :style="{
            height: '50px',
            marginLeft: '25px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }"
          class="notification-header"
        >
          <sdHeading as="h6">
            {{ i18n.t('profileView.referalParent') }}
          </sdHeading>
        </div>
        <div class="settings-form__wrapper parent">
          <BasicFormWrapper>
            <a-form>
              <a-form-item name="confirmation" :label="i18n.t('profileView.parentName')">
                <a-input
                  :placeholder="
                    contactsState.referral_program.parent
                      ? `${contactsState.referral_program.parent.code}`
                      : i18n.t('profileView.hasntParent')
                  "
                  :disabled="true"
                />
              </a-form-item>
            </a-form>
          </BasicFormWrapper>
        </div>
      </a-col>
    </a-row>
  </sdCards>
</template>
<script>
import { BasicFormWrapper } from '../../../styled';
import { defineComponent, ref, computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import helper from '@/config/helpers/helper';
import { useI18n } from 'vue-i18n';
import { message } from 'ant-design-vue';
import { countries } from '@/config/helpers/JSONdata';
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU';
import moment from 'moment';
import { useRoute } from 'vue-router';
import Inputmask from 'inputmask';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

const Profile = defineComponent({
  name: 'Profile',
  components: { BasicFormWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const i18n = useI18n();
    const route = useRoute();
    moment.locale(i18n.locale.value);

    const contactUpdate = ref({});
    const profileUpdate = ref({});
    const phoneUpdate = ref({ phone: '' });
    const changableField = ref({ profile: false, email: false, phone: false });
    const alertType = ref('');
    const alertText = ref('');
    const profile = ref();
    const emailForm = ref();
    const phoneForm = ref();
    const placeholder = ref();
    const iti = ref();
    const countryCode = ref('');
    const phoneItem = ref();

    let validationCount = 0;
    let initialize = 0;
    let phoneFull = null;
    const dateValidation = () => {
      if (!profileUpdate.value.date) {
        return Promise.reject(i18n.t('profileView.rules.date'));
      }

      if ((Date.now() - new Date(profileUpdate.value.date._d)) / 1000 / 3600 / 24 / 365 < 18) {
        return Promise.reject(i18n.t('messages.dateBirthLess'));
      }
      return Promise.resolve();
    };

    const errorValidator = (check) => {
      let isError = null;
      if (errors.value && Object.keys(errors.value).length > validationCount) {
        Object.keys(errors.value).forEach((err) => {
          if (err == check.field) {
            isError = Promise.reject(i18n.t(`authPage.${err}`) + ' ' + errors.value[err].join(i18n.t('messages.and')));
          }
        });

        if (isError) {
          validationCount++;
          return isError;
        }
      }
      return Promise.resolve();
    };

    const rules = reactive({
      last_name: [
        {
          required: true,
          message: i18n.t('profileView.rules.last_name'),
          trigger: 'change',
        },
        {
          validator: errorValidator,
        },
      ],
      first_name: [
        {
          required: true,
          message: i18n.t('profileView.rules.first_name'),
          trigger: 'change',
        },
        {
          validator: errorValidator,
        },
      ],
      date: [
        {
          trigger: 'change',
          required: true,
          validator: dateValidation,
        },
        {
          validator: errorValidator,
        },
      ],
      country: [
        {
          required: true,
          message: i18n.t('profileView.rules.country'),
          trigger: 'change',
        },
        {
          validator: errorValidator,
        },
      ],
      password: [
        {
          required: true,
          message: i18n.t('profileView.rules.password'),
          trigger: 'change',
        },
        {
          validator: errorValidator,
        },
      ],
      email: [
        {
          required: true,
          message: i18n.t('profileView.rules.email'),
          trigger: 'change',
        },
        {
          validator: errorValidator,
        },
      ],
      phone: [
        {
          required: true,
          message: i18n.t('profileView.rules.phone'),
          trigger: 'change',
        },
        {
          validator: errorValidator,
        },
      ],
    });

    const profileState = computed(() => state.profile.userData);
    const contactsState = computed(() => state.profile.data);
    const isLoading = computed(() => state.profile.isLoading);
    const errors = computed(() => state.profile.error);

    const translateCountries = computed(() =>
      countries
        ? Object.entries(countries).map((value) => {
            return { value: value[1].value, label: i18n.t('countries.' + value[1].value) };
          })
        : countries,
    );

    const changableData = (data) => {
      if (data === 'profile') {
        changableField.value.profile = !changableField.value.profile;
        if (changableField.value.profile) {
          profileUpdate.value = {
            first_name: profileState.value.first_name,
            middle_name: profileState.value.middle_name,
            last_name: profileState.value.last_name,
            date: profileState.value.date_birth,
            country: profileState.value.country,
          };
        }
      } else if (data === 'email') {
        contactUpdate.value.email = contactsState.value.email;
        changableField.value.email = !changableField.value.email;
      } else if (data === 'phone') {
        if (initialize % 2 == 0) {
          changableField.value.phone = !changableField.value.phone;
        }

        // При первом старте данные начальные берем, потом из фиксированного полного номера при ините редактора!
        if (!initialize) {
          phoneUpdate.value.phone = contactsState.value.phone;
        } else if (initialize % 2 == 0) {
          phoneUpdate.value.phone = phoneFull;
        }

        setTimeout(() => {
          if (initialize % 2 == 0) {
            // Срабатывает при активации редактирования телефона
            iti.value = intlTelInput(document.querySelector('.js-phone-mask'), {
              // utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js',
              utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js',
              // initialCountry: 'ru',
              preferredCountries: ['ru', 'by', 'pl', 'vn', 'ua', 'lv', 'lt', 'ee'],
              separateDialCode: true,
              // customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
              //   let placeholder = selectedCountryPlaceholder;
              //   if (selectedCountryData.iso2 == 'id') {
              //     placeholder = placeholder + '0000';
              //   }
              //   return placeholder;
              // },
            });
            setTimeout(() => {
              phoneItem.value.focus();
              phoneItem.value.blur();
            }, 100);
          } else {
            // Срабатывает при закрытие редактирование
            iti.value.destroy();
            changableField.value.phone = !changableField.value.phone;
          }
          // if (!initialize && phoneUpdate.value.phone && phoneUpdate.value.phone != '') {
          if (changableField.value.phone && phoneUpdate.value.phone && phoneUpdate.value.phone != '') {
            // Срабатывает во время старта изменения поля если заполнено
            const code = document.querySelector('.iti__selected-dial-code').innerHTML;
            phoneFull = phoneUpdate.value.phone;
            const codePhone = phoneFull.slice(0, code.length);
            countryCode.value = code;
            if (code === codePhone) {
              phoneUpdate.value.phone = phoneFull.slice(code.length);
            }
            // phoneUpdate.value.phone = phoneUpdate.value.phone
            //   .split('')
            //   .map((e) => (countryCode.value.includes(e) ? '' : e))
            //   .join('');
          }
          initialize++;
        }, 100);
      }
    };

    const handleEmailFinish = () => {
      const errorMsg = helper().checkUserMail(contactUpdate.value);

      if (errorMsg == true) {
        dispatch('axiosChangeEmail', contactUpdate.value).then(() => {
          if (errors.value == null) {
            message.success(i18n.t('messages.emailChanged'));
            document.querySelectorAll('.ant-form-item-control').forEach((e) => e.classList.remove('has-success'));
            changableData('email');
          } else {
            emailForm.value.validate();
          }
        });
      } else {
        message.warning(i18n.t(errorMsg));
      }
    };
    const handlePhoneFinish = () => {
      // console.log('handlePhoneFinish');
      const phoneWithMask = phoneUpdate.value.phone;
      phoneFull = countryCode.value + phoneUpdate.value.phone;
      phoneUpdate.value.phone = Inputmask.unmask(phoneFull, { mask: '999999999[9][9][9][9][9][9]' });

      dispatch('axiosChangePhone', phoneUpdate.value).then(() => {
        if (errors.value == null) {
          message.success(i18n.t('messages.phoneChanged'));
          document.querySelectorAll('.ant-form-item-control').forEach((e) => e.classList.remove('has-success'));
          changableData('phone');
        } else {
          phoneForm.value.validate();
        }
      });
      phoneUpdate.value.phone = phoneWithMask;
    };

    const handleProfileFinish = () => {
      if (profileUpdate.value.date) {
        profileUpdate.value.date_birth = profileUpdate.value.date._d;
      } else {
        message.error(i18n.t('messages.inputNormalDate'));
        return;
      }

      Object.keys(profileUpdate).forEach((k) => {
        if (typeof profileUpdate[k] == 'string') {
          profileUpdate[k].trim() == '' ? delete profileUpdate[k] : profileUpdate[k].trim();
        }
      });
      const sendableData = {};

      for (const [key, value] of Object.entries(profileUpdate.value)) {
        if (key !== 'date') {
          sendableData[key] = value;
        }
      }

      dispatch('axiosChangeProfileData', sendableData).then(() => {
        if (errors.value == null) {
          message.success(i18n.t('messages.dataChanged'));
          document.querySelectorAll('.ant-form-item-control').forEach((e) => e.classList.remove('has-success'));
          changableData('profile');
        } else {
          validationCount = 0;
          profile.value.validate();
        }
      });
    };

    const changePhone = (data) => {
      // При таком условие не срабатывает при повторном редактирование
      // if (placeholder.value != data.target.placeholder) {
      countryCode.value = document.querySelector('.iti__selected-dial-code').innerHTML;
      placeholder.value = data.target.placeholder;

      let mask = data.target.placeholder.replace(/[0-9_]/gi, '9');

      // Для индонезии добавляем символы номера 9-13 символов
      if (countryCode.value === '+62') {
        mask = mask + '[9][9][9][9]';
      }
      Inputmask(mask).mask(document.querySelector('.js-phone-mask'));
      // }
    };
    onMounted(() => {
      if (route.query.open && profileState.value) {
        changableData('profile');
        //changableData('contacts');
      }
    });

    return {
      profileState,
      contactsState,
      profileUpdate,
      contactUpdate,
      phoneUpdate,
      alertText,
      alertType,
      i18n,
      changableField,
      isLoading,
      translateCountries,
      countries,
      locale,
      profile,
      emailForm,
      phoneForm,
      rules,
      placeholder,
      countryCode,
      phoneItem,
      changePhone,
      handleEmailFinish,
      handleProfileFinish,
      handlePhoneFinish,
      changableData,
    };
  },
});

export default Profile;
</script>
